// extracted by mini-css-extract-plugin
export var contact = "Contact-module--contact--52OYU";
export var headerContact = "Contact-module--headerContact--BIZxm";
export var title = "Contact-module--title--WlEvr";
export var headerLogo = "Contact-module--headerLogo--HvTDl";
export var headerImage = "Contact-module--headerImage--PrH+t";
export var contactWrapper = "Contact-module--contactWrapper--Da0Pr";
export var inner = "Contact-module--inner--tEYDe";
export var inputFiles = "Contact-module--inputFiles--VESXD";
export var submitButton = "Contact-module--submitButton--izwTj";
export var inputGroup = "Contact-module--inputGroup--XTJCS";
export var inputLabel = "Contact-module--inputLabel--TVLN0";
export var mainSelect = "Contact-module--mainSelect--pWFtV";
export var inputsWrapper = "Contact-module--inputsWrapper--rZIus";
export var inputUpload = "Contact-module--inputUpload--Io6n+";
export var inputDescription = "Contact-module--inputDescription--q20dQ";
export var terms = "Contact-module--terms--lDZbA";
export var checkbox = "Contact-module--checkbox--OSxah";
export var invalidInputForm = "Contact-module--invalidInputForm--mplos";
export var errorInputFormatColor = "Contact-module--errorInputFormatColor--wgOM8";
export var errorInputFormat = "Contact-module--errorInputFormat--OTk8-";