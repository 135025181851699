import { ReactElement, useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { useDependency } from "../../contexts/DependencyContext";
import { showModal, closeLatestModal } from "../../hooks/UseModal";
import { useForm } from "react-hook-form";
import Helmet from "../../components/Helmet";
import { formImages } from "../../components/Icons";
import { formLogo } from "../../components/Icons";
import FilesUploader from "../../components/Shared/FilesUploader";
import { AcceptModal } from "../../components/Shared/Modals/AcceptModal";
import { LoadingModal } from "../../components/Shared/Modals/LoadingModal";
import Captcha, { ICaptchaHandle } from "../../components/Shared/Captcha";
import { ContactRequestsService } from "../../services/http/ContactRequestService";
import { TelemetryService } from "../../services/telemetry/TelemetryService";
import * as styles from "./Contact.module.scss";

interface ContactProps {
    data: GatsbyTypes.contactPageQuery;
}

export default function ContactPage({ data }: ContactProps): ReactElement {
    const [formData, setFormData] = useState<{ [name: string]: string }>({});
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm({ reValidateMode: "onBlur" });
    const captchaRef = useRef<ICaptchaHandle>(null);
    const contactRequestsService = useDependency(ContactRequestsService);
    const telemetryService = useDependency(TelemetryService);
    const notification = data.strapi.contactPage?.notification;
    const maxFiles = 10;

    function handleValueChange(event: React.ChangeEvent<HTMLInputElement>) {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    function handleTextAreaValueChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    function removeFile(file: File) {
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
    }

    async function resetForm(): Promise<void> {
        setFormData({});
        setFiles([]);
        setTermsAccepted(false);
        reset({});
        captchaRef?.current?.reset();
        await showModal(AcceptModal, { title: notification?.successTitle ?? "", content: notification?.successDescription ?? "", buttonText: notification?.acceptButtonText ?? "", error: false });
    }

    async function onSubmit(): Promise<void> {
        showModal(LoadingModal, { content: notification?.loadingText ?? "" });

        try {
            await contactRequestsService.saveRequestAsync(formData as unknown as GatsbyTypes.strapi_ContactRequestInput, files);
            closeLatestModal();
            await resetForm();
        } catch (error) {
            closeLatestModal();
            telemetryService.error(error);
            await showModal(AcceptModal, { title: notification?.errorTitle ?? "", content: notification?.errorDescription ?? "", buttonText: notification?.acceptButtonText ?? "", error: true });
        }
    }

    return (
        <>
            <Helmet title={"Contact"} description={""} />
            <section className="mainBox">
                <div className={styles.contact}>
                    <div className={styles.headerContact}>
                        <div className={styles.title}>
                            <h1>{data.strapi.contactPage?.base?.heading}</h1>
                            <div className={styles.headerLogo}>{formLogo}</div>
                            <div dangerouslySetInnerHTML={{ __html: data.strapi.contactPage?.base?.paragraph ?? "" }}></div>
                        </div>
                        <div className={styles.headerImage}>{formImages.contact}</div>
                    </div>

                    <div className={styles.contactWrapper}>
                        <div className={styles.inner}>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <div>
                                    <div className={styles.inputGroup}>
                                        <input
                                            {...register("email", {
                                                required: { value: true, message: data.strapi.contactPage?.baseForm?.requiredFieldText ?? "" },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: data.strapi.contactPage?.baseForm?.invalidEmailFieldText ?? "",
                                                },
                                            })}
                                            type="text"
                                            maxLength={255}
                                            className={errors.email ? styles.invalidInputForm : ""}
                                            value={formData.email ?? ""}
                                            onChange={handleValueChange}
                                        />
                                        <label className={`${styles.inputLabel} ${errors.email && styles.errorInputFormatColor}`} htmlFor="email">
                                            {data.strapi.contactPage?.baseForm?.emailLabel}
                                        </label>
                                        {errors.email && <span className={styles.errorInputFormat}>{errors.email.message}</span>}
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <input
                                            maxLength={255}
                                            type="text"
                                            {...register("subject", { required: { value: true, message: data.strapi.contactPage?.baseForm?.requiredFieldText ?? "" } })}
                                            className={errors.subject ? styles.invalidInputForm : ""}
                                            value={formData.subject ?? ""}
                                            onChange={handleValueChange}
                                        />
                                        <label className={`${styles.inputLabel} ${errors.subject && styles.errorInputFormatColor}`} htmlFor="subject">
                                            {data.strapi.contactPage?.baseForm?.subjectLabel}
                                        </label>
                                        {errors.subject && <span className={styles.errorInputFormat}>{errors.subject.message}</span>}
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <textarea
                                            {...register("description", { required: { value: true, message: data.strapi.contactPage?.baseForm?.requiredFieldText ?? "" } })}
                                            className={errors.description ? styles.invalidInputForm : ""}
                                            value={formData.description ?? ""}
                                            onChange={handleTextAreaValueChange}
                                            required
                                        />
                                        <label className={`${styles.inputLabel} ${errors.description && styles.errorInputFormatColor}`} htmlFor="description">
                                            {data.strapi.contactPage?.baseForm?.descriptionLabel}
                                        </label>
                                        {errors.description && <span className={styles.errorInputFormat}>{errors.description.message}</span>}
                                    </div>
                                    <div className={styles.inputFiles}>
                                        <FilesUploader
                                            label={data.strapi.contactPage?.baseForm?.attachmentsLabel ?? ""}
                                            placeholder={data.strapi.contactPage?.baseForm?.attachmentsPlaceholder ?? ""}
                                            maxSize={52428800}
                                            maxFiles={maxFiles}
                                            files={files}
                                            onFileAdded={newFiles => setFiles([...files, ...newFiles])}
                                            onRemoveFile={removeFile}
                                            onRemoveAll={() => setFiles([])}
                                            removeAllText={data.strapi.contactPage?.baseForm?.removeAllFileUploaderField ?? ""}
                                            tooltip={data.strapi.contactPage?.baseForm?.uploadTooltip?.paragraph ?? ""}
                                        />
                                    </div>
                                </div>

                                <div className={styles.terms}>
                                    <span className={styles.checkbox}>
                                        <input type="checkbox" checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)} className={styles.checkbox} />
                                        <label htmlFor="terms">{data.strapi.contactPage?.baseForm?.acceptTermsLabel}</label>
                                    </span>
                                    <div dangerouslySetInnerHTML={{ __html: data.strapi.contactPage?.baseForm?.termsTooltip?.paragraph ?? "" }} />
                                </div>

                                <Captcha onVerified={token => setFormData({ ...formData, token })} ref={captchaRef} />

                                <input type="submit" value={data.strapi.contactPage?.baseForm?.submitLabel} disabled={!termsAccepted || !formData?.token} className={styles.submitButton} />
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export const query = graphql`
    query contactPage {
        strapi {
            contactPage {
                base {
                    heading
                    paragraph
                }
                baseForm {
                    acceptTermsLabel
                    attachmentsLabel
                    attachmentsPlaceholder
                    descriptionLabel
                    descriptionPlaceholder
                    emailLabel
                    subjectLabel
                    submitLabel
                    requiredFieldText
                    invalidEmailFieldText
                    removeAllFileUploaderField
                    termsTooltip {
                        heading
                        paragraph
                    }
                    uploadTooltip {
                        heading
                        paragraph
                    }
                }
                notification {
                    successTitle
                    successDescription
                    errorTitle
                    errorDescription
                    acceptButtonText
                    loadingText
                }
            }
        }
    }
`;
