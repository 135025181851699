import { ContextCollection } from "../../contexts/DependencyContext";
import { StrapiClient } from "./clients/StrapiClient";

export class ContactRequestsService {
    private readonly url: string;

    constructor(private readonly apiClient: StrapiClient) {
        this.url = "contact-requests";
    }

    async saveRequestAsync(body: GatsbyTypes.strapi_ContactRequestInput, files: File[]) {
        const formData = new FormData();
        formData.append("data", JSON.stringify(body));

        for (const file of files) {
            formData.append("files.attachments", file, file.name);
        }

        await this.apiClient.post(this.url, {}, formData);
    }
}

ContextCollection.registerSingleton(ContactRequestsService, [StrapiClient]);
